import service from '@/utils/request'
import {getMapItemById} from '@/utils/tools'
import {getCompany} from '@/api/company'

export const companies = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    idMap: new Map(),
    list:[],
    userIdMap: {},
  },
  actionStatus: 0,
  mutations: {
    setIdMap(state, value) {
      state.idMap = value
    },
    setList(state, value) {
      state.list = value
    },
    setActionStatus(state, value) {
      state.actionStatus = value
    },
    setCompany(state, data) {
      state.idMap.set(data.id, data)
    },
    setUserIdMap(state, value) {
      state.userIdMap = {...state.userIdMap, ...value}
    }
  },
  actions: {
    async request({ commit, state }, isRequest) {
      if(state.actionStatus > 0 && !isRequest)
      {
        return;
      }
      commit('setActionStatus', 1)
      await getCompany({}).then((res) => {
        commit('setActionStatus', 2)
        if (res.code === 0) {
          let idMap = new Map();
          res.data.list.forEach(item => {
            idMap.set(item.id, item);
          });
          commit('setIdMap',idMap);
          commit('setList',res.data.list);
        }
      }).catch((e) => {
        console.log(e);
      });
    },
    async requestExt({commit, state}, id) {
      return getCompany({id, ext: true})
          .then((r)=>{
            commit('setCompany', r.data)
            if (r.data.users) {
              for (let u of r.data.users) {
                commit('setUserIdMap', {[u.id]: u})
              }
            }
            return r.data
          })
    }
  },
  getters: {
    getById: (state) => (id) => {
      return getMapItemById(state.idMap,id);
    },
    getByAbbr: (state) => (abbr) => {
      return state.list.find(item=>{return item.abbreviation === abbr})
    },
  }
}
