import { createRouter, createWebHashHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/view/login/index.vue')
  },
  {
    path: '/init',
    name: 'Init',
    component: () => import('@/view/dbinit/index.vue')
  },
  {
    path: '/printable',
    name: 'Printable',
    component: () => import('@/view/printable/index.vue')
  },
  /*
  {
    path: '/dashboard',
    component: () => import("@/view/layout/index.vue"),
    meta:{
      name:"Dashboard",
    },
    children: [
      {
        name: "layout",
        path: "",
        component: () => import(`@/view/dashboard/index.vue.bk`),
        meta:{
          name:"Monitor",
        },
      },
      
    ],
  },
*./
  
  /*
  {
    path: '/monitorcenter',
    name: 'MonitorCenter',
    component: () => import('@/views/dashboard/monitorCenter/index.vue')
  },
  */
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
