import service from '@/utils/request'
import {getListItemByIndex,getMapItemById} from '@/utils/tools'
export const models = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    modelMap: new Map(),
    idMap: new Map(),
    list:[],
    datas:[],
    gpsModels: [{ value: 0, name: '无效' },
    { value: 1, name: '手动设置', },
    { value: 2, name: '自动获取', },
    { value: 3, name: '从其他设备获取', },],
    alertGrades: [{ value: 0, name: '不告警' },
    { value: 1, name: '提醒' },
    { value: 2, name: '告警' },
    { value: 3, name: '严重告警' },],
  },
  mutations: {
    setModelMap(state, value) {
      state.modelMap = value
    },
    setIdMap(state, value) {
      state.idMap = value
    },
    setList(state, value) {
      state.list = value
    },
    setDatas(state, value) {
      state.datas = value
    },
  },
  actions: {
    async request({ commit, state }, isRequest) {
      if(state.list.length > 0 && !isRequest)
      {
        return;
      }
      let a = service({
        url: '/api/v1/models',
        method: 'get',
        params:{ext:true}
      }),
          b =       service({
            url: '/api/v1/devdatas',
            method: "get",
            params:{ext:true}
          })

      await a
          .then((res) => {
        if (res.code === 0) {
          let modelMap = new Map();
          let idMap = new Map();
           res.data.forEach(item => {
            modelMap.set(item.model, item);
            idMap.set(item.id, item);
          });
          commit('setModelMap',modelMap);
          commit('setIdMap',idMap);
          commit('setList',res.data);
        }
      }).catch((e) => {
        console.log(e);
      });

      await b
        .then((res) => {
          if (res.code === 0) {
            commit('setDatas',res.data.list);
          }
        })
        .catch((e) => {
          console.log(e);
        });
      },
  },
  getters: {
    //设备种类,1:普通设备,2:容器设备,3:智能设备"`
    getNormalIds(state) {
      let array = [];
      state.list.forEach(item => {
        //设备种类,1:普通设备,2:容器设备,3:智能设备"`
        if (item.type == 1) {
          array.push(item.id);
        }
      });
      return array;
    },
    getNormals(state) {
      let array = [];
      state.list.forEach(item => {
        //设备种类,1:普通设备,2:容器设备,3:智能设备"`
        if (item.type == 1) {
          array.push(item);
        }
      });
      return array;
    },
    getGroundIds(state) {
      let array = [];
      state.list.forEach(item => {
        if (item.type == 2) {
          array.push(item.id);
        }
      });
      return array;
    },
    getGrounds(state) {
      let array = [];
      state.list.forEach(item => {
        if (item.type == 2) {
          array.push(item);
        }
      });
      return array;
    },
    getIntelligentIds(state) {
      let array = [];
      state.list.forEach(item => {
        if (item.type == 3) {
          array.push(item.id);
        }
      });
      return array;
    },
    getIntelligents(state) {
      let array = [];
      state.list.forEach(item => {
        if (item.type == 3) {
          array.push(item);
        }
      });
      return array;
    },
    getById: (state) => (id) => {
      return getMapItemById(state.idMap,id);
    },
    getGpsModelByIndex: (state) => (index) => {
      return getListItemByIndex(state.gpsModels,index);
    },
    getByKeyword: (state) => (keyword) => {
      return state.list.find(x => x.dataFmt?.abbreviation === keyword)
    },
  }
}
