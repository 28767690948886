import { createI18n }  from 'vue-i18n'

import { store } from '@/store'

import zhLocale from 'element-plus/es/locale/lang/zh-cn'
import enLocale from 'element-plus/es/locale/lang/en'
import frLocale from 'element-plus/es/locale/lang/fr'

import { get } from 'lodash'

const lang: { [key: string]: any } = {
  zh: {
    ...zhLocale,
  },
  en: {
    ...enLocale,
  },
  fr: {
    ...frLocale,
  },
}
const translations = require.context('./i18n', false, /\.js$/)
Array.prototype.forEach.call(translations.keys(), (path) => {
  const tran = translations(path).default
  const partKey = path.match(/\w+/)[0] //retrieve the filename as partial keys
  lang.zh[partKey] = lang.zh[partKey] || {}
  lang.en[partKey] = lang.en[partKey] || {}
  lang.fr[partKey] = lang.fr[partKey] || {}
  Object.keys(tran).forEach((k) => {
    const { en, zh, fr } = tran[k]
    lang.en[partKey][k] = en
    lang.fr[partKey][k] = fr
    lang.zh[partKey][k] = zh
  })
})

const i18nInstance = createI18n({
  messages: lang,
  locale: store.state.user.lang,
  legacy: false,
})

// export const getLocalMessage = (path: string): string => {
//   const local = i18nInstance.global.locale
//   const messages = i18nInstance.global.messages[local]
//   return get(messages, path)
// }

export default i18nInstance
