export const getListItemByIndex =  (list, index) => {
    //console.log("getListItemByIndex into list index",list,index)
    let ret = {
        id:0,
        name:'0',
        label:'0',
    }
    if (typeof (list) == "undefined" || list.length == 0 ) {
      return ret;
    }

    if (list.length < index - 1 || typeof (index) == "undefined") {
      return list[0];
    }
    let item = list[index];
    if(typeof (item) == "undefined")
    {
      return list[0];
    }
    return item
  }
  
  export const getMapItemById = (map, id) => {
    let ret = {
      id:id,
      name:id,
      label:id,
    }
    if (typeof (map) == "undefined" || typeof (id) == "undefined") {
      return ret;
    }
    let item = map.size>0?map.get(id):undefined;
    if(typeof (item) == "undefined")
    {
      return ret;
    }
    return item;
  }