export default {
  title: {
    zh: '线路中心',
    en: 'Lines',
    fr: 'Lignes',
  },
  cableList: {
    zh: '线路列表',
    en: 'Lines',
    fr: 'Lignes',
  },
  cableName: {
    zh: '线路名称',
    en: 'Line name',
    fr: 'Nom du ligne',
  },
  cableLength: {
    zh: '长度',
    en: 'Length',
    fr: 'Longueur',
  },
  cableLengthUnit: {
    zh: '米',
    en: 'm',
    fr: 'm',
  },
  deviceList: {
    zh: '设备列表',
    en: 'Devices',
    fr: 'Appareils',
  },
  deviceName: {
    zh: '名称',
    en: 'Name',
    fr: 'Nom',
  },
  warningList: {
    zh: '告警列表',
    en: 'Warnings',
    fr: 'Avertissements',
  },
  warningBoxAndDevice: {
    zh: '所属接地箱和设备',
    en: 'Box and device',
    fr: 'Boîte et appareil',
  },
  warningItem: {
    zh: '告警项',
    en: 'Item',
    fr: 'Article',
  },
  warningValue: {
    zh: '值',
    en: 'Value',
    fr: 'Valeur',
  },
  noData: {
    zh: '实时监测暂无告警',
    en: 'No warning.',
    fr: 'Pas de avertissement.',
  }
}
