export default {
  title: {
    zh: '设备',
    en: 'Devices',
    fr: 'Appareils',
  },
  buttonExport: {
    zh: '导出',
    en: 'Export',
    fr: 'Exporter',
  },
  buttonImport: {
    zh: '导入',
    en: 'Import',
    fr: 'Importer',
  },
  tabBox: {
    zh: '接地箱',
    en: 'Grounding boxes',
    fr: 'Boîtes de terre',
  },
  tabDevice: {
    zh: '智能设备',
    en: 'Smart devices',
    fr: 'Appareils intelligents',
  },
  searchCable: {
    zh: '线路',
    en: 'Line',
    fr: 'Ligne',
  },
  searchCompany: {
    zh: '公司',
    en: 'Company',
    fr: 'Entreprise',
  },
  searchDeviceCode: {
    zh: '设备编号',
    en: 'Device code',
    fr: 'Code appareil',
  },
  searchUserId: {
    zh: '识别码',
    en: 'Identifier',
    fr: 'Identifiant',
  },
  searchSubmit: {
    zh: '查询',
    en: 'Search',
    fr: 'Chercher',
  },
  searchReset: {
    zh: '重置',
    en: 'Reset',
    fr: 'Réinitialiser',
  },
  formButtonNew: {
    zh: '新增',
    en: 'New',
    fr: 'Créer',
  },
  formButtonDelete: {
    zh: '删除',
    en: 'Delete',
    fr: 'Supprimer',
  },
  formButtonEnable: {
    zh: '启用',
    en: 'Enable',
    fr: 'Activer',
  },
  formButtonDisable: {
    zh: '停用',
    en: 'Disable',
    fr: 'Désactiver',
  },
  cancel: {
    zh: '取消',
    en: 'Cancel',
    fr: 'Annuler',
  },
  confirm: {
    zh: '确定',
    en: 'Confirm',
    fr: 'Confirmer',
  },
  deleteConfirmQuestion: {
    zh: '确定要删除吗？',
    en: 'Are you sure to delete?',
    fr: 'Êtes-vous sûr de le(s) supprimer ?',
  },
  enableConfirmQuestion: {
    zh: '确定要启用当前选中的所有设备吗？',
    en: 'Are you sure you want to enable all currently selected devices?',
    fr: 'Voulez-vous vraiment activer tous les appareils actuellement sélectionnés ?',
  },
  disableConfirmQuestion: {
    zh: '确定要停用当前选中的所有设备吗？',
    en: 'Are you sure you want to disable all currently selected devices?',
    fr: 'Voulez-vous vraiment désactiver tous les appareils actuellement sélectionnés ?',
  },
  formBoxModel: {
    zh: '接地类型',
    en: 'Grounding type',
    fr: 'Type de mise à la terre',
  },
  formDeviceModel: {
    zh: '设备类型',
    en: 'Device type',
    fr: 'Type d\'appareil',
  },
  formBoxBodyModel: {
    zh: '箱体型式',
    en: 'Box body model',
    fr: 'Modèle de la boîte',
  },
  formCable: {
    zh: '所属线路',
    en: 'Line',
    fr: 'Ligne',
  },
  formBox: {
    zh: '所属接地箱',
    en: 'Box',
    fr: 'Boîte',
  },
  formCompany: {
    zh: '所属公司',
    en: 'Company',
    fr: 'Entreprise',
  },
  formManufacture: {
    zh: '生产厂家',
    en: 'Manufactured by',
    fr: 'Fabriqué par',
  },
  formMaintenance: {
    zh: '运维厂商',
    en: 'Maintained by',
    fr: 'Entretenu par',
  },
  formMaintainerId: {
    zh: '运维人员',
    en: 'Maintainer',
    fr: 'Entreteneur',
  },
  formBoxName: {
    zh: '杆塔沟/接地箱',
    en: 'Installation position/box name',
    fr: 'Position d\'installation/nom de la boîte',
  },
  formDeviceName: {
    zh: '位置描述',
    en: 'Installation position/device name',
    fr: 'Position d\'installation/nom de l\'appareil',
  },
  formLength: {
    zh: '电缆长度',
    en: 'Length of adjacent cables',
    fr: 'Longueur des câbles adjacents',
  },
  formQueue: {
    zh: '连接顺序',
    en: 'Order in line',
    fr: 'L\'ordre dans la ligne',
  },
  formCreatedAt: {
    zh: '创建时间',
    en: 'Created at',
    fr: 'Créé à',
  },
  formOperationAt: {
    zh: '投运时间',
    en: 'Operating from',
    fr: 'Opérant à partir de',
  },
  formServiceLife: {
    zh: '使用年限',
    en: 'Operation time',
    fr: 'Durées de l\'opération',
  },
  formUserId: {
    zh: '识别码',
    en: 'Identifier',
    fr: 'Identifiant',
  },
  formDeviceCode: {
    zh: '设备编号',
    en: 'Device code',
    fr: 'Code appareil',
  },
  formComment: {
    zh: '备注',
    en: 'Remark',
    fr: 'Remarque',
  },
  formGpsModel: {
    zh: 'GPS模式',
    en: 'GPS mode',
    fr: 'Mode GPS',
  },
  formGpsDevId: {
    zh: 'GPS数据源ID',
    en: 'GPS provider ID',
    fr: 'ID du fournisseur du GPS',
  },
  formManualGps: {
    zh: '手动GPS数据',
    en: 'GPS manually set',
    fr: 'GPS réglé manuellement',
  },
  formLongitude: {
    zh: '经度',
    en: 'Longitude',
    fr: 'Longitude',
  },
  formLatitude: {
    zh: '纬度',
    en: 'Latitude',
    fr: 'Latitude',
  },
  formConjunct: {
    zh: '共用电缆接头的设备的设备编号',
    en: 'Device code of the other device connected to the same joint',
    fr: 'Code d\'appareil de l\'autre appareil sur la même articulation',
  },
  expand: {
    zh: '详情',
    en: 'Details',
    fr: 'Détails',
  },
  operations: {
    zh: '操作',
    en: 'Operations',
    fr: 'Opérations',
  },
  enable: {
    zh: '使能',
    en: 'Enable',
    fr: 'Activer',
  },
  edit: {
    zh: '变更',
    en: 'Edit',
    fr: 'Modifier',
  },
  delete: {
    zh: '删除',
    en: 'Delete',
    fr: 'Supprimer',
  },
  dialogPropConfigTitle: {
    zh: '属性配置',
    en: 'Properties configuration',
    fr: 'Configuration des propriétés',
  },
  dialogDataConfigTitle: {
    zh: '数据配置',
    en: 'Data configuration',
    fr: 'Configuration des données',
  },
  messageGps3Tip: {
    zh: '*当无法从指定设备获取时，将使用手动设置的数据',
    en: '*When unable to obtain from the specified device, the manually set data will be used.',
    fr: '*En cas d\'impossibilité d\'obtenir à partir de l\'appareil spécifié, les données définies manuellement seront utilisées.',
  },
  buttonUploadPhoto: {
    zh: '点此上传文件',
    en: 'Click here to upload',
    fr: 'Cliquez ici pour télécharger',
  },
  messageEmptyFieldError: {
    zh: '参数不能为空',
    en: 'Parameter cannot be empty.',
    fr: 'Le paramètre ne peut pas être vide.',
  },
  messageCoordinateEmptyError: {
    zh: '请输入经纬度',
    en: 'Please enter latitude and longitude.',
    fr: 'Veuillez entrer la latitude et la longitude.',
  },
  messageDeleteNoSelection: {
    zh: '请选择要删除的数据',
    en: 'Please select what to delete.',
    fr: 'Veuillez sélectionner ce que vous souhaitez supprimer.',
  },
  newBoxNamePlaceholder: {
    zh: '新接地箱',
    en: 'New grounding box',
    fr: 'Nouvelle boîtes de terre',
  },
  newDeviceNamePlaceholder: {
    zh: '新设备',
    en: 'New smart device',
    fr: 'Nouvel appareil intelligent',
  },
  deleteConfirmTitle: {
    zh: '提示',
    en: 'Hint',
    fr: 'Indice',
  },
  deleteSuccess: {
    zh: '删除成功',
    en: 'Successfully deleted.',
    fr: 'Supprimé avec succès.',
  },
  createOrEditSuccess: {
    zh: '创建/更改成功',
    en: 'Successfully created/edited.',
    fr: 'Créé/modifié avec succès.',
  },
  updateSuccess: {
    zh: '更新成功',
    en: 'Successfully updated.',
    fr: 'Mise à jour réussie.',
  },
  // 数据配置
  dataItem: {
    zh: '数据项',
    en: 'Item',
    fr: 'Article',
  },
  dataItemValid: {
    zh: '有效',
    en: 'Valid',
    fr: 'Valide',
  },
  alertGrade: {
    zh: '告警等级',
    en: 'Warning level',
    fr: 'Niveau d\'avertissement',
  },
  lowerThreshold: {
    zh: '阈值下限',
    en: 'Min',
    fr: 'Min',
  },
  upperThreshold: {
    zh: '阈值上限',
    en: 'Max',
    fr: 'Max',
  },
  buttonJumpToDeviceCenter: {
    zh: '转到设备中心',
    en: 'To Device center',
    fr: 'Au Centre des appareils',
  },
}
