//const modules1 = import.meta.glob('./src/*.js');
// const modules1 = import.meta.glob('./view/**/*.vue') 
// console.log("modules1:",modules1);
// const modules2 = import.meta.glob('./view/**/**/*.vue')
// var modules3 = {};
// Object.assign(modules3,modules1);
// Object.assign(modules3,modules2);
// console.log("modules2:",modules2);
// console.log("modules3:",modules3);


const modules = {
  '../view/layout/index.vue': ()=> import ('../view/layout/index.vue'),
  '../view/dashboard/cablecenter/index.vue': ()=> import ('../view/dashboard/cablecenter/index.vue'),
  '../view/dashboard/devicecenter/index.vue': ()=> import ('../view/dashboard/devicecenter/index.vue'),
  '../view/dashboard/alertcenter/index.vue': ()=> import ('../view/dashboard/alertcenter/index.vue'),
  '../view/dashboard/managecenter/index.vue': ()=> import ('../view/dashboard/managecenter/index.vue'),
  '../view/dashboard/index.vue': ()=> import ('../view/dashboard/index.vue'),
  '../view/dashboard/components/mapCables/index.vue': ()=> import ('../view/dashboard/components/mapCables/index.vue'),
  '../view/manage/user/user.vue': ()=> import ('../view/manage/user/user.vue'),
  '../view/manage/api/api.vue': ()=> import ('../view/manage/api/api.vue'),
  '../view/manage/authority/authority.vue': ()=> import ('../view/manage/authority/authority.vue'),
  '../view/manage/menu/menu.vue': ()=> import ('../view/manage/menu/menu.vue'),
  '../view/manage/operation/sysOperationRecord.vue': ()=> import ('../view/manage/operation/sysOperationRecord.vue'),
  '../view/manage/about/index.vue': ()=> import ('../view/manage/about/index.vue'),
  '../view/manage/cable/cable.vue': ()=> import ('../view/manage/cable/cable.vue'),
  '../view/manage/devDevices/devDevices.vue': ()=> import ('../view/manage/devDevices/devDevices.vue'),
  '../view/manage/company/company.vue': ()=> import ('../view/manage/company/company.vue'),
  '../view/manage/companyUsers/companyUsers.vue': ()=> import ('../view/manage/companyUsers/companyUsers.vue'),
  '../view/manage/warningData/warningData.vue': ()=> import ('../view/manage/warningData/warningData.vue'),
  '../view/manage/warningRole/warningRole.vue': ()=> import ('../view/manage/warningRole/warningRole.vue'),
  '../view/manage/groundBoxMonitorConfig/groundBoxMonitorConfig.vue': ()=> import ('../view/manage/groundBoxMonitorConfig/groundBoxMonitorConfig.vue'),
  '../view/manage/partialDischargeMonitorData/partialDischargeMonitorData.vue': ()=> import ('../view/manage/partialDischargeMonitorData/partialDischargeMonitorData.vue'),
  '../view/manage/circulationData/circulationData.vue': ()=> import ('../view/manage/circulationData/circulationData.vue'),
  '../view/manage/devModels/devModels.vue': ()=> import ('../view/manage/devModels/devModels.vue'),
  '../view/manage/alarms/index.vue': ()=> import ('../view/manage/alarms/index.vue'),
  '../view/manage/south/south.vue': ()=> import ('../view/manage/south/south.vue'),
}

export const asyncRouterHandle = (asyncRouter) => {
  asyncRouter.forEach(item => {
    const k = '../' + item.component
    item.component = modules[k]
    if (item.component === undefined) {
      delete item.component
    }
    if (item.children && item.children.length > 0) {
      asyncRouterHandle(item.children)
    }
  })
}
