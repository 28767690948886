import service from '@/utils/request'

const url = '/api/v1/alarms'

export const getAlarms = ({id, ...params}) => {
  if (id) {
    id = '/' + id
  } else {
    id = ''
  }
  return service({
    url: url + id,
    method: "get",
    params,
  })
}

export const patchAlarms = (data) => {
  return service({
    url,
    method: 'patch',
    data
  })
}

export const deleteAlarms = (id) => {
  if (id) {
    id = '/' + id
  } else {
    id = ''
  }
  return service({
    url: url + id,
    method: 'delete',
  })
}
