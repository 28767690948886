export default {
  title: {
    zh: '告警',
    en: 'Warnings',
    fr: 'Avertissements',
  },
  buttonDeleteSelection: {
    zh: '删除',
    en: 'Delete',
    fr: 'Supprimer',
  },
  buttonDeleteInvalid: {
    zh: '清除所有无效告警',
    en: 'Delete all invalid warnings',
    fr: 'Supprimer tous les avertissements non valides',
  },
  warningCableAndBox: {
    zh: '所属线路和接地箱',
    en: 'Cable and grounding box',
    fr: 'Câble et boîte de terre',
  },
  warningDevice: {
    zh: '所属设备',
    en: 'Smart device',
    fr: 'Appareil intelligent',
  },
  warningItem: {
    zh: '告警项',
    en: 'Item',
    fr: 'Article',
  },
  warningValue: {
    zh: '值',
    en: 'Value',
    fr: 'Valeur',
  },
  created: {
    zh: '告警时间',
    en: 'Started at',
    fr: 'Commencé à',
  },
  confirmed: {
    zh: '确认时间',
    en: 'Confirmed at',
    fr: 'Confirmé à',
  },
  reparied: {
    zh: '处理时间',
    en: 'Resolved at',
    fr: 'Résolu à',
  },
  frequency: {
    zh: '累计次数',
    en: 'Count',
    fr: 'Compte',
  },
  operations: {
    zh: '操作',
    en: 'Operations',
    fr: 'Opérations',
  },
  delete: {
    zh: '删除',
    en: 'Delete',
    fr: 'Supprimer',
  },
  cancel: {
    zh: '取消',
    en: 'Cancel',
    fr: 'Annuler',
  },
  confirm: {
    zh: '确定',
    en: 'Confirm',
    fr: 'Confirmer',
  },
  deleteConfirmQuestion: {
    zh: '确定要删除吗？',
    en: 'Are you sure to delete?',
    fr: 'Êtes-vous sûr de le(s) supprimer ?',
  },
  messageNoInvalidWarning: {
    zh: '不存在无效告警',
    en: 'No invalid warning found.',
    fr: 'Aucun avertissement invalide n\'a été trouvé.',
  },
}
