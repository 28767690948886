export default {
  线路: {
    zh: '线路',
    en: 'Line',
    fr: 'Ligne',
  },
  电缆: {
    zh: "电缆",
    en: 'Cable',
    fr: 'Câble',
  },
  电缆段: {
    zh: '电缆段',
    en: 'Cable segment',
    fr: 'Segment de câble',
  },
  电缆终端: {
    zh: '电缆终端',
    en: '',
    fr: '',
  },
  电缆接头: {
    zh: '电缆接头',
    en: 'Joint',
    fr: 'Articulation',
  },
  直接接地箱: {
    zh: '直接接地箱',
    en: 'Direct grounding box',
    fr: 'Boîte de terre directe',
  },
  保护接地箱: {
    zh: '保护接地箱',
    en: 'Protective grounding box',
    fr: 'Boîte de terre de protection',
  },
  交叉接地箱: {
    zh: '交叉接地箱',
    en: 'Cross-connect grounding box',
    fr: 'Boîte de terre d\'interconnexion',
  },
  边缘代理设备: {
    zh: '边缘代理设备',
    en: 'Edge gateway',
    fr: 'Passerelle périphérique',
  },
  环流监测设备: {
    zh: '环流监测设备',
    en: 'Circulation monitoring device',
    fr: 'Appareil de surveillance de la circulation',
  },
  局部放电监测设备: {
    zh: '局部放电监测设备',
    en: 'Partial discharge monitoring device',
    fr: 'Appareil de surveillance des décharges partielles',
  },
  光纤测温设备: {
    zh: '光纤测温设备',
    en: 'Fiber optic temperature measuring device',
    fr: 'Appareil de mesure de température des fibres optiques',
  },
  智能井盖设备: {
    zh: '智能井盖设备',
    en: 'Smart manhole cover',
    fr: 'Couvercle de regard intelligent',
  },
  振动防盗装置: {
    zh: '振动防盗装置',
    en: 'Vibration sensing anti-theft device',
    fr: 'Appareil antivol de détection de vibrations',
  },
  低功耗环流监测设备: {
    zh: '低功耗环流监测设备',
    en: 'Low power circulation monitoring device',
    fr: 'Appareil de surveillance de la circulation à faible puissance',
  },
}
