export default {
  不告警: {
    zh: '不告警',
    en: 'Disabled',
    fr: 'Désactivé',
  },
  提醒: {
    zh: '提醒',
    en: 'Warning',
    fr: 'Avertissement',
  },
  告警: {
    zh: '告警',
    en: 'Alarm',
    fr: 'Alarme',
  },
  严重告警: {
    zh: '严重告警',
    en: 'Critical alarm',
    fr: 'Alarme critique',
  },
}