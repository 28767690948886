/**
 * 网站配置文件
 */

const config = {
  appName: 'pmserve',
  appLogo: '',
  showViteLogo: true
}

export default config
