import service from '@/utils/request'

let baseurl = '/api/v1/devices'

export const getDevices = (params) => {
  return service({
    url: baseurl,
    method: 'get',
    params
  })
}

export const getDevice = ({id, ...params}) => { 
  if (id === undefined) {
    id = ''
  } else {
    id = '/' + id
  }
  return service({
    url: baseurl + id,
    method: 'get',
    params,
  })
}

export const addDevice = (data) => {
  return service({
    url: baseurl,
    method: 'post',
    data
  })
}

export const deleteDevice = (id) => {
  if (id === undefined) {
    return 
  } else {
    id = '/' + id
  }
  return service({
    url: baseurl + id,
    method: 'delete',
  })
}

export const updateDevice = (data) => {
  return service({
    url: baseurl,
    method: 'patch',
    data,
  })
}

const _enableDevice = (id, boolean) => {
  if (id === undefined) {
    return
  }
  return service({
    url: baseurl + '/' + id,
    method: 'patch',
    params: {
      enable: boolean
    },
  })
}

export const enableDevice = (id) => {
  return _enableDevice(id, true)
}

export const disableDevice = (id) => {
  return _enableDevice(id, false)
}