export default {
  title: {
    zh: '设备中心',
    en: 'Devices',
    fr: 'Appareils',
  },
  updatedAtTag: {
    zh: '更新时间',
    en: 'Updated',
    fr: 'Mis à jour',
  },
  noData: {
    zh: '无数据',
    en: 'Never',
    fr: 'Jamais',
  },
  recentlyUpdated: {
    zh: '刚刚更新',
    en: 'Just now',
    fr: 'À l\'instant',
  },
  updatedHowLongAgo: {
    zh: '{0}前',
    en: '{0} ago',
    fr: 'Il y a {0}',
  },
  manufacturerTag: {
    zh: '生产厂家',
    en: 'Manufactured by',
    fr: 'Fabriqué par',
  },
  maintainerTag: {
    zh: '运维厂商',
    en: 'Maintained by',
    fr: 'Entretenu par',
  },
  deviceCodeTag: {
    zh: '设备编号',
    en: 'Device code',
    fr: 'Code appareil',
  },
  userIdTag: {
    zh: '识别码',
    en: 'Identifier',
    fr: 'Identifiant',
  },
  commentTag: {
    zh: '备注',
    en: 'Remark',
    fr: 'Remarque',
  },
  exportDeviceTable: {
    zh: '导出设备表格',
    en: 'Export device table',
    fr: 'Exporter le tableau des appareils',
  },
  exportDeviceData: {
    zh: '导出设备数据',
    en: 'Export device data',
    fr: 'Exporter les données de l\'appareil',
  },
  deviceDataTable: {
    zh: '表格',
    en: 'Table',
    fr: 'Tableau',
  },
  deviceDataChart: {
    zh: '折线图',
    en: 'Chart',
    fr: 'Graphique',
  },
  deviceDataTableTime: {
    zh: '时间',
    en: 'Time',
    fr: 'Temps',
  },
  timePickerStart: {
    zh: '起始时间',
    en: 'Start',
    fr: 'Début',
  },
  timePickerEnd: {
    zh: '终止时间',
    en: 'End',
    fr: 'Fin',
  },
  last10min: {
    zh: '最近十分钟',
    en: 'Last 10 minutes',
    fr: '10 dernières minutes',
  },
  last30min: {
    zh: '最近半小时',
    en: 'Last 30 minutes',
    fr: '30 dernières minutes',
  },
  last1h: {
    zh: '最近一小时',
    en: 'Last 60 minutes',
    fr: '60 dernières minutes',
  },
  last1d: {
    zh: '最近一天',
    en: 'Last 24 hours',
    fr: '24 dernières heures',
  },
  last3d: {
    zh: '最近三天',
    en: 'Last 72 hours',
    fr: '72 dernières heures',
  },
  last7d: {
    zh: '最近一周',
    en: 'Last 7 days',
    fr: '7 derniers jours',
  },
  last30d: {
    zh: '最近一个月',
    en: 'Last 30 days',
    fr: '30 derniers jours',
  },
  last90d: {
    zh: '最近三个月',
    en: 'Last 90 days',
    fr: '90 derniers jours',
  },
  last180d: {
    zh: '最近半年',
    en: 'Last 180 days',
    fr: '180 derniers jours',
  },
  last365d: {
    zh: '最近一年',
    en: 'Last 365 days',
    fr: '365 derniers jours',
  },
  deviceList: {
    zh: '设备列表',
    en: 'Device list',
    fr: 'Liste des appareils',
  },
  deviceCode: {
    zh: '设备编号',
    en: 'Device code',
    fr: 'Code appareil',
  },
  ofWhatCable: {
    zh: '所属线路：',
    en: 'Line',
    fr: 'Ligne',
  },
  ofWhatBox: {
    zh: '所属接地箱：',
    en: 'Box',
    fr: 'Boîte',
  },
  warningList: {
    zh: '告警列表',
    en: 'Warning list',
    fr: 'Liste des avertissements',
  },
  warningItem: {
    zh: '告警项',
    en: 'Item',
    fr: 'Article',
  },
  warningValue: {
    zh: '值',
    en: 'Value',
    fr: 'Valeur',
  },
}
