export const presets = {
  namespaced: true,
  state: {},
  mutations: {
    setCompany(state, {cableId, data}) {
      cableId = cableId.toString()
      state[cableId].company = {
        ...state[cableId].company,
        ...data
      }
    },
    setCfg(state, {cableId, data}) {
      cableId = cableId.toString()
      if (state[cableId].cfg === undefined) {
        state[cableId].cfg = {}
      }
      state[cableId].cfg = {
        ...state[cableId].cfg,
        ...data
      }
    },
    initEmpty(state, {cableId}) {
      cableId = cableId.toString()
      state[cableId] = {}
    },
    clear(state) {
      for (let i in state) {
        state[i] = {}
      }
    }
  },
  actions: {
    initCompany({commit, dispatch}, {cableId}) {
      cableId = cableId.toString()
      dispatch('emptyCompanyPreset').then((r) => {
        commit("setCompany", {cableId, data: r})
      })
    },
    initCfg({state, dispatch}, {cableId}) {
      cableId = cableId.toString()
      dispatch('emptyDatacfgPreset').then((r) => {
        state[cableId].cfg = r
      })
    },
    clear({commit}){
      commit('clear')
    },
    init({commit, dispatch}, cableId) {
      cableId = cableId.toString()
      commit('initEmpty', {cableId})
      dispatch('initCompany', {cableId})
      dispatch('initCfg', {cableId})
    },
    update({commit}, cableId, company, cfg) {
      cableId = cableId.toString()
      commit('setCompany', {cableId, data: company})
      commit('setCfg', {cableId, data: cfg})
    },
    emptyCompanyPreset() {
      return {
        maintainer: {},
        manufacturer: {},
        user: {},
      }
    },
    emptyDatacfgPreset() {
      return {
        earthCur: {name: '接地电流', lo: 0, hi: 50},
        earthVol: {name: '接地电压', lo: 0, hi: 100},
        runCur: {name: '运行电流', lo: 0, hi: 1000},
        temp: {name: '接头温度', lo: -20, hi: 90},
        loadRatio: {name: '负荷比', lo: 0, hi: 50},

        batVol: {name: '电池电压', lo: 7, hi: 15},
        temprature: {name: '环境温度', lo: -20, hi: 60},
        humidity: {name: '环境湿度', lo: 0, hi: 100},
        simImsi: {name: 'sim卡IMSI码', lo: 0, hi: 50},
        simRssi: {name: '无线信号强度', lo: 0, hi: 50},
        chassisDoor: {name: '柜门打开', lo: 0, hi: 50},
        chassisSmoke: {name: '机柜烟感', lo: 0, hi: 50},
        chassisTilt: {name: '机柜倾斜', lo: 0, hi: 50},
        chassisShock: {name: '机柜振动', lo: 0, hi: 50},
        gpsEn: {name: 'GPS获取', lo: 0, hi: 50},
        gpsLongitude: {name: 'GPS经度', lo: 0, hi: 50},
        gpsLatitude: {name: 'GPS纬度', lo: 0, hi: 50},
        gpsState: {name: 'GPS寻星状态', lo: 0, hi: 50},
      }
    }
  }
}