import { createApp } from 'vue'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import './styles/element_visiable.scss'

import router from './router'
import '@/permission'
import { store } from '@/store'
import auth from '@/global/auth'
import '@/global/basic'
import run from '@/global/basic'
import i18n from '@/i18n'

// import dataV from '@jiaminghi/data-view';
import DataVVue3 from '@kjgl77/datav-vue3'

//
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import En from 'element-plus/es/locale/lang/en'
import Fr from 'element-plus/es/locale/lang/fr'

// 引入全局css
import './assets/scss/style.scss';
// 引入图表（所有图标见 icon 目录下的 demo_index.html）
import './assets/icon/iconfont.css'
// 引入 全局注册组件
import PublicComponent from '@/components/componentInstall';

import App from './App.vue'

const app = createApp(App)

const elementLang = store.state.user.lang === 'zh' ? zhCn : store.state.user.lang === 'fr' ? Fr : En

app.use(run)
.use(auth)
.use(store)
.use(router)
.use(DataVVue3)
.use(PublicComponent)
.use(i18n)
.use(ElementPlus, { locale: elementLang }).mount('#app')

// export default app