import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import { user } from '@/store/module/user'
import { router } from '@/store/module/router'
import { dictionary } from '@/store/module/dictionary'
import { cables } from '@/store/module/cables'
import { models } from '@/store/module/models'
import { companies } from '@/store/module/companies'
import { presets } from "@/store/module/presets";
import { printable } from "@/store/module/printable";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['user', 'presets', 'printable']
})

export const store = createStore({
  modules: {
    user,
    router,
    dictionary,
    cables,
    models,
    companies,
    presets,
    printable,
  },
  plugins: [vuexLocal.plugin]
})

