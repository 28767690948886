export default {
  title: {
    zh: '监控中心',
    en: 'Overview',
    fr: 'Aperçu',
  },
  cableInfoTitle: {
    zh: '电缆信息',
    en: 'Lines',
    fr: 'Lignes',
  },
  cableTotalLength: {
    zh: '线路总长度',
    en: 'Total length',
    fr: 'Longueur totale',
  },
  cableTotalLengthUnit: {
    zh: '千米',
    en: 'km',
    fr: 'km',
  },
  cableCount: {
    zh: '线路数量',
    en: 'Lines',
    fr: 'Lignes',
  },
  cableCountUnit: {
    zh: '条',
    en: '',
    fr: '',
  },
  boxCount: {
    zh: '接地箱数量',
    en: 'Grounding boxes',
    fr: 'Boîtes de terre',
  },
  boxCountUnit: {
    zh: '台',
    en: '',
    fr: '',
  },
  cableSegmentCount: {
    zh: '电缆数量',
    en: 'Cable segments',
    fr: 'Segments de câble',
  },
  cableSegmentCountUnit: {
    zh: '段',
    en: '',
    fr: '',
  },
  cableLengthChartTitle: {
    zh: '线路长度年变化',
    en: 'Annual changes of line length',
    fr: 'Changements annuels de la longueur totale',
  },
  cableLengthChartUnit: {
    zh: '单位：千米',
    en: 'Unit: km',
    fr: "L'unité: km",
  },
  cableAgeChartTitle: {
    zh: '线路投运年限',
    en: 'Line operation time',
    fr: 'Durées d\'opération des lignes',
  },
  cableAgeChartLessThanOneYear: {
    zh: '不满一年',
    en: 'Less than 1 year',
    fr: 'Moins que 1 an',
  },
  cableAgeChartYear: {
    zh: '年',
    en: 'year(s)',
    fr: 'an(s)',
  },
  cableAgeChartCableUnit: {
    zh: '条',
    en: '',
    fr: '',
  },
  warningDataChartTitle: {
    zh: '本月告警信息',
    en: 'Monthly warnings',
    fr: 'Avertissements mensuels',
  },
  warningRepaired: {
    zh: '已处理',
    en: 'Solved',
    fr: 'Résolu',
  },
  warningUnattended: {
    zh: '未确认',
    en: 'Unconfirmed',
    fr: 'Non confirmé',
  },
  warningConfirmed: {
    zh: '已确认待处理',
    en: 'Confirmed',
    fr: 'Confirmé',
  },
  dynamicInfoOnlineDevices: {
    zh: '在线设备/总设备数',
    en: 'Online devices/Total',
    fr: 'Appareils en ligne/Total',
  },
  dynamicInfoWarningDevices: {
    zh: '告警设备数',
    en: 'Devices warning',
    fr: 'Appareils en avertissant',
  },
  dynamicInfoUnattendedWarnings: {
    zh: '未处理告警数',
    en: 'Unsolved warnings',
    fr: 'Avertissements non résolus',
  },
  dynamicInfoAlarmingBoxes: {
    zh: '告警接地箱数',
    en: 'Grounding boxes warning',
    fr: 'Boîtes de terre en avertissant',
  },
  dynamicInfoAlarmingCables: {
    zh: '告警线路数',
    en: 'Lines warning',
    fr: 'Lignes en avertissant',
  },
  deviceAmountChartTitle: {
    zh: '设备投运数量年变化',
    en: 'Annual changes of number of devices',
    fr: 'Changements annuels du nombre des appareils',
  },
  deviceAmountChartUnit: {
    zh: '单位: 台',
    en: '',
    fr: '',
  },
  deviceAgeChartTitle: {
    zh: '设备投运年限',
    en: 'Accessory operation time',
    fr: 'Durées d\'opération des accessoires',
  },
  deviceAgeChartLessThanOneYear: {
    zh: '不满一年',
    en: 'Less than 1 year',
    fr: 'Moins que 1 an',
  },
  deviceAgeChartYear: {
    zh: '年',
    en: 'year(s)',
    fr: 'an(s)',
  },
  deviceAgeChartDeviceUnit: {
    zh: '台',
    en: '',
    fr: '',
  },
}
