export default {
  监控中心: {
    zh: '监控中心',
    en: 'Overview',
    fr: 'Aperçu',
  },
  线路中心: {
    zh: '线路中心',
    en: 'Cables',
    fr: 'Câbles',
  },
  设备中心: {
    zh: '设备中心',
    en: 'Devices',
    fr: 'Appareils',
  },
  告警中心: {
    zh: '告警中心',
    en: 'Warnings',
    fr: 'Avertissements',
  },
  管理中心: {
    zh: '管理中心',
    en: 'Managements',
    fr: 'Gestions',
  },
  线路: {
    zh: '线路',
    en: 'Cables',
    fr: 'Câbles',
  },
  设备: {
    zh: '设备',
    en: 'Devices',
    fr: 'Appareils',
  },
  公司: {
    zh: '公司',
    en: 'Companies',
    fr: 'Entreprises',
  },
  账号: {
    zh: '账号',
    en: 'Users',
    fr: 'Utilisateurs',
  },
  告警: {
    zh: '告警',
    en: 'Warnings',
    fr: 'Avertissements',
  },
  api: {
    zh: 'api',
    en: 'APIs',
    fr: 'APIs',
  },
  角色: {
    zh: '角色',
    en: 'Roles',
    fr: 'Rôles',
  },
  菜单: {
    zh: '菜单',
    en: 'Menus',
    fr: 'Menus',
  },
  操作历史: {
    zh: '操作历史',
    en: 'History',
    fr: 'Histoire',
  },
  设备类型: {
    zh: '设备类型',
    en: 'Device types',
    fr: 'Types d\'appareil',
  },
}