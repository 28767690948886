import { login, getUserInfo, setUserInfo } from '@/api/user'
import { getCompany } from '../../api/company'
import { jsonInBlacklist } from '@/api/jwt'
import router from '@/router/index'
import { ElMessage } from 'element-plus'

export const user = {
  namespaced: true,
  state: {
    userInfo: {
      uuid: '',
      nickName: '',
      headerImg: '',
      authority: {},
      sideMode: 'dark',
      nonMapMode: false,
      activeColor: '#4D70FF',
      baseColor: '#fff',
      companyId: 0,
      companyUuid:'',
      companyName:'',
    },
    token: '',
    lang: 'zh',
  },
  mutations: {
    setUserInfo(state, userInfo) {
      // 这里的 `state` 对象是模块的局部状态
      state.userInfo = userInfo
    },
    setToken(state, token) {
      // 这里的 `state` 对象是模块的局部状态
      state.token = token
    },
    NeedInit(state) {
      state.userInfo = {}
      state.token = ''
      localStorage.clear()
      router.push({ name: 'Init', replace: true })
    },
    LoginOut(state) {
      state.userInfo = {}
      state.token = ''
      localStorage.clear()
      router.push({ name: 'Login', replace: true })
      window.location.reload()
    },
    ResetUserInfo(state, userInfo = {}) {
      state.userInfo = { ...state.userInfo,
        ...userInfo
      }
    },
    ChangeSideMode: (state, val) => {
      state.userInfo.sideMode = val
    },
    ChangeLang(state, lang) {
      if (['en', 'zh', 'fr'].includes(lang)) {
        state.lang = lang
      }
    },
  },
  actions: {
    async GetUserInfo({ commit, dispatch }) {
      // console.log("GetUserInfo into");
      const res = await getUserInfo()
      // console.log("GetUserInfo res:",res);
      if (res.code === 0) {
        commit('setUserInfo', res.data.userInfo)
        // console.log("GetUserInfo res.data.userInfo:",res.data.userInfo);
        getCompany({id:res.data.userInfo.devCompanyId}).then(
          (res) =>
          {
            let usrInfo = {companyName: res.data.name}
            commit('ResetUserInfo', usrInfo)
            // console.log("getUserCompany res:",res);
          }
        )
      }
      else
      {
        dispatch('LoginOut')
      }
    },
    async LoginIn({ commit, dispatch, rootGetters, getters }, loginInfo) {
      const res = await login(loginInfo)
      if (res.code === 0) {
        commit('setUserInfo', res.data.user)
        commit('setToken', res.data.token)

        dispatch('cables/request', {}, { root: true })
        // dispatch('models/request', {}, { root: true })
        dispatch('companies/request', {}, { root: true })
        await dispatch('router/SetAsyncRouter', {}, { root: true })
        const asyncRouters = rootGetters['router/asyncRouters']
        asyncRouters.forEach(asyncRouter => {
          router.addRoute(asyncRouter)
        })

        // const redirect = router.history.current.query.redirect
        // console.log(redirect)
        // if (redirect) {
        //     router.push({ path: redirect })
        // } else {
//        console.log(getters['userInfo'].authority.defaultRouter)
        router.push({ name: getters['userInfo'].authority.defaultRouter })
        // }
//        router.push({ path: '/layout/dashboard', })
        return true
      }
    },
    async LoginOut({ commit, dispatch }) {
      dispatch('presets/clear', {}, { root: true })
      commit('LoginOut')
      // const res = await jsonInBlacklist()
      // if (res.code === 0) {
      //   commit('LoginOut')
      // }
    },
    async changeSideMode({ commit, state }, data) {
      const res = await setUserInfo({ sideMode: data, id: state.userInfo.id })
      if (res.code === 0) {
        commit('ChangeSideMode', data)
        ElMessage({
          type: 'success',
          message: '设置成功'
        })
      }
    },
  },
  getters: {
    userInfo(state) {
      return state.userInfo
    },
    token(state) {
      return state.token
    },
    mode(state) {
      return state.userInfo.sideMode
    },
    sideMode(state) {
      if (state.userInfo.sideMode === 'dark') {
        return '#191a23'
      } else if (state.userInfo.sideMode === 'light') {
        return '#fff'
      } else {
        return state.userInfo.sideMode
      }
    },
    baseColor(state) {
      if (state.userInfo.sideMode === 'dark') {
        return '#fff'
      } else if (state.userInfo.sideMode === 'light') {
        return '#191a23'
      } else {
        return state.userInfo.baseColor
      }
    },
    activeColor(state) {
      if (state.userInfo.sideMode === 'dark' || state.userInfo.sideMode === 'light') {
        return '#4D70FF'
      }
      return state.userInfo.activeColor
    }
  }
}
