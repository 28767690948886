export const printable = {
  namespaced: true,
  state: {
    flag: 0,
    id: 0,
    name: '',
    model: '',
    cable: '',
    box: '',
    dataUrl: '',
    start: '',
    end: '',
    alarms: {},
  },
  mutations: {
    setDevice(state, {rootGetters, id}) {
      state.id = id
      let device = rootGetters['cables/getDeviceById'](id)
      state.name = device.name
      state.model = device.model.name
      let box = rootGetters['cables/getDeviceById'](device.boxId)
      state.box = box.name
      state.boxModel = box.model.name
      state.cable = rootGetters['cables/getById'](box.cableId).name
      state.flag += 1
    },
    setDataUrl(state, dataUrl) {
      state.dataUrl = dataUrl
    },
    setTime(state, {start, end}) {
      state.start = start
      state.end = end
    },
    setAlarms(state, alarmsData) {
      let r = []
      for (let al of alarmsData) {
        if (al.state > 2) {
          continue
        }
        r.push({
          id: al.id,
          state: al.state,
          created: al.createdat,
          updated: al.updatedat,
          confirmed: al.confirmedat,
          value: al.value,
          frequency: al.frequency,
          name: al.dataCfg.dataUnit.name,
          unit: al.dataCfg.dataUnit.unit,
        })
      }
      state.alarms = r
    },
  },
  actions: {
    setDevice({commit, rootGetters}, id) {
      commit('setDevice', {rootGetters, id})
      commit('setAlarms', rootGetters['cables/getDeviceAlarmsById'](id).list)
    }
  }
}