export default {
  title: {
    zh: '线路',
    en: 'Lines',
    fr: 'Lignes',
  },
  searchCable: {
    zh: '线路名',
    en: 'Name of line',
    fr: 'Nom de la ligne',
  },
  searchReset: {
    zh: '重置',
    en: 'Reset',
    fr: 'Réinitialiser',
  },
  searchSubmit: {
    zh: '查询',
    en: 'Search',
    fr: 'Chercher',
  },
  formButtonNew: {
    zh: '新增',
    en: 'New',
    fr: 'Créer',
  },
  nameOfCable: {
    zh: '线路名',
    en: 'Line name',
    fr: 'Nom de la ligne',
  },
  section: {
    zh: '安装位置区段',
    en: 'Section',
    fr: 'Section',
  },
  voltageLevel: {
    zh: '电压等级',
    en: 'Voltage level',
    fr: 'Niveau de tension',
  },
  province: {
    zh: '省份',
    en: 'Province',
    fr: 'Province',
  },
  city: {
    zh: '城市',
    en: 'City',
    fr: 'Ville',
  },
  box: {
    zh: '接地箱',
    en: 'Grounding boxes',
    fr: 'Boîtes de terre',
  },
  company: {
    zh: '所属公司',
    en: 'Company',
    fr: 'Entreprise',
  },
  maintenance: {
    zh: '运维厂商',
    en: 'Maintained by',
    fr: 'Entretenu par',
  },
  manufacture: {
    zh: '生产厂家',
    en: 'Manufactured by',
    fr: 'Fabriqué par',
  },
  expand: {
    zh: '详情',
    en: 'Details',
    fr: 'Détails',
  },
  abbreviation: {
    zh: '线路代号',
    en: 'Abbreviation',
    fr: 'Abréviation',
  },
  length: {
    zh: '长度',
    en: 'Length',
    fr: 'Longueur',
  },
  lineCount: {
    zh: '电缆段数',
    en: 'Cable segments',
    fr: 'Segments de câble',
  },
  createdAt: {
    zh: '创建时间',
    en: 'Created at',
    fr: 'Créé à',
  },
  comment: {
    zh: '备注',
    en: 'Remark',
    fr: 'Remarque',
  },
  operations: {
    zh: '操作',
    en: 'Operations',
    fr: 'Opérations',
  },
  config: {
    zh: '配置',
    en: 'Configure',
    fr: 'Configurer',
  },
  edit: {
    zh: '变更',
    en: 'Edit',
    fr: 'Modifier',
  },
  delete: {
    zh: '删除',
    en: 'Delete',
    fr: 'Supprimer',
  },
  dialogTitleEdit: {
    zh: '修改线路',
    en: 'Line edit',
    fr: 'Modification de la ligne',
  },
  dialogTitleNew: {
    zh: '新增线路',
    en: 'New line',
    fr: 'Nouvelle ligne',
  },
  cancel: {
    zh: '取消',
    en: 'Cancel',
    fr: 'Annuler',
  },
  confirm: {
    zh: '确定',
    en: 'Confirm',
    fr: 'Confirmer',
  },
  newCableNamePlaceholder: {
    zh: '新线路',
    en: 'New line',
    fr: 'Nouvelle ligne',
  },
  newBoxNamePlaceholder: {
    zh: '新接地箱',
    en: 'New grounding box',
    fr: 'Nouvelle boîtes de terre',
  },
  deleteConfirmQuestion: {
    zh: '确定要删除吗？',
    en: 'Are you sure to delete?',
    fr: 'Êtes-vous sûr de le(s) supprimer ?',
  },
  deleteConfirmTitle: {
    zh: '提示',
    en: 'Hint',
    fr: 'Indice',
  },
  deleteSuccess: {
    zh: '删除成功',
    en: 'Successfully deleted.',
    fr: 'Supprimé avec succès.',
  },
  createOrEditSuccess: {
    zh: '创建/更改成功',
    en: 'Successfully created/edited.',
    fr: 'Créé/modifié avec succès.',
  },
  buttonExport: {
    zh: '导出',
    en: 'Export',
    fr: 'Exporter',
  },
  buttonImport: {
    zh: '导入',
    en: 'Import',
    fr: 'Importer',
  },
  searchCompany: {
    zh: '公司',
    en: 'Company',
    fr: 'Entreprise',
  }
}
