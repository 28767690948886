import { getCable } from '@/api/cable'
import { getDevice } from "@/api/devices";
import { getAlarms } from "@/api/alarms";
import lodash from 'lodash'
import service from '@/utils/request'

export const cables = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    list: [],
    idMap: new Map(),
    deviceIdMap: new Map(),
    deviceValuesIdMap: new Map(),
    deviceValues: {},
    deviceAlarmsIdMap: new Map(),
    deviceAlarms: {},
    current: undefined,
    currentDevice: "",
    unitMap: {},
    alarmsCount: {
      total: 0,
      confirmed: 0,
      repaired: 0,
      unattended: 0,
    },
    actionStatus: {
      // action 状态, 0 表示没有请求过, 1 表示请求已发出, 2 表示数据已存在
      alarms: 0,
      values: 0,
      all: 0,
    },
    // 地图和表格联动
    boxClicked: 0,
    deviceClicked: 0,
    unitIdMap: new Map(),
    dragging: false,
    importDialogVisible: false,
  },
  mutations: {
    setCables(state, newValue) {
      state.list = newValue;
    },
    setCurrent(state, newValue) {
      state.current = newValue;
    },
    setCurrentDevice(state, value) {
      state.currentDevice = value
    },
    setIdMap(state, value) {
      state.idMap = value
    },
    setDeviceIdMap(state, value) {
      state.deviceIdMap = value
    },
    setDeviceValuesIdMap(state, value) {
      state.deviceValuesIdMap = value
    },
    setDeviceValues(state, value) {
      state.deviceValues = value
    },
    setDeviceAlarmsIdMap(state, value) {
      state.deviceAlarmsIdMap = value
    },
    setDeviceAlarms(state, value) {
      state.deviceAlarms = value
    },
    setUnitMap(state, value) {
      state.unitMap = value
    },
    setAlarmsCount(state, value) {
      state.alarmsCount = value
    },
    setActionStatus(state, value) {
      state.actionStatus = { ...state.actionStatus, ...value }
    },
    setBoxClicked(state, value) {
      state.boxClicked = value
    },
    setDeviceClicked(state, value) {
      state.deviceClicked = value
    },
    setDrag(state, value) {
      state.dragging = value
    },
  },
  actions: {
    async getDeviceAlarms({ commit, dispatch, state, getters, rootGetters }, isRequest) {
      if (!isRequest && state.actionStatus.alarms > 0) {
        return;
      }

      commit('setActionStatus', { alarms: 1 })

      getAlarms({ ext: true })
        .then((res) => {
          if (res.code === 0) {
            let unattended = 0
            let confirmed = 0
            let repaired = 0
            let total = 0
            let idMap = new Map()
            for (let alarm of res.data.list) {
              // 分别计数
              total += 1
              let isRepaired = false
              switch (alarm.state) {
                case 1:
                  unattended += 1
                  break
                case 2:
                  confirmed += 1
                  break
                case 3:
                  repaired += 1
                  isRepaired = true
              }
              if (isRepaired) {
                continue
              }
              // 写入 DeviceAlarms 和 DeviceAlarmsIdMap
              let device = idMap.get(alarm.devDeviceID)
              if (device === undefined) {
                let d = {
                  list: [],
                  map: new Map()
                }
                d.list.push(alarm)
                d.map.set(alarm.id, alarm)
                idMap.set(alarm.devDeviceID, d)
              } else {
                device.list.push(alarm)
                device.map.set(alarm.id, alarm)
              }
            }
            commit("setDeviceAlarms", res.data)
            commit("setDeviceAlarmsIdMap", idMap)
            commit('setAlarmsCount',
              {
                unattended,
                confirmed,
                repaired,
                total
              })
            commit('setActionStatus', { alarms: 2 })
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getDeviceValues({ commit, dispatch, state, getters, rootGetters }, isRequest) {
      if (!isRequest && state.actionStatus.values > 0) {
        return;
      }

      commit('setActionStatus', { values: 1 })

      let userInfo = rootGetters['user/userInfo']

      service({
        url: '/api/v1/values',
        method: "get",
        params: { ext: true }
      })
        .then((res) => {
          if (res.code === 0) {
            let idMap = new Map();
            for (let keyStr in res.data.values) {
              let deviceId = keyStr * 1;
              let valuesItem = res.data.values[keyStr];
              valuesItem.devCfgs = [];

              let device = state.deviceIdMap.get(deviceId);
              if (!device || !device.devCfgs || device.devCfgs.length === 0) {
                continue;
              }
              let cfgsObject = lodash.cloneDeep({
                devCfgs: device.devCfgs
              });

              cfgsObject.devCfgs.forEach(cfg => {
                let dataUnit = cfg.dataUnit;
                cfg.dataValue = null;
                for (let keyword in valuesItem) {
                  let dataValue = valuesItem[keyword];
                  if (keyword === dataUnit.keyword) {
                    cfg.dataValue = dataValue;
                  }
                }
              })
              valuesItem.devCfgs = cfgsObject.devCfgs;
              idMap.set(deviceId, valuesItem);
            }

            commit("setDeviceValues", res.data)
            commit("setDeviceValuesIdMap", idMap)
            commit('setActionStatus', { values: 2 })
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async request({ commit, dispatch, state, getters, rootState }, refresh) {
      if (!refresh && state.actionStatus.all > 0) {
        return
      }
      commit('setActionStatus', { all: 1 })
      async function z() {
        let a = getCable({}),
          b = getDevice({ withCfg: true }),
          c = dispatch('models/request', null, { root: true })
        await c
        return [await a, await b]
      }
      await z().then((r) => {
        let cablesR = r[0],
          devicesR = r[1]
        if (cablesR.code !== 0) {
          console.log(cablesR.msg)
          return
        }
        if (devicesR.code !== 0) {
          console.log(devicesR.msg)
          return
        }
        let cables = cablesR.data.list,
          devices = devicesR.data.list,
          idMap = new Map(),
          deviceIdMap = new Map(),
          unitIdMap = new Map(),
          unitMap = {}
        for (let m of rootState.models.list) {
          if (!m.dataFmt || !m.dataFmt.units) {
            continue
          }
          for (let u of m.dataFmt.units) {
            if (!unitIdMap.has(u.id)) {
              unitIdMap.set(u.id, u)
            }
          }
        }
        for (let c of cables) {
          if (!c.gndboxs) {
            c.gndboxs = []
          }
          idMap.set(c.id, c)
        }
        for (let d of devices) {
          deviceIdMap.set(d.id, d)
          d.model = rootState.models.idMap.get(d.modelId)
          let deviceUnitMap = {}
          if (!d.devCfgs) {
            continue
          }
          for (let cfg of d.devCfgs) {
            cfg.dataUnit = unitIdMap.get(cfg.devDataUnitId)
            // 写入 unitMap
            if (!cfg.dataUnit) {
              continue
            }
            deviceUnitMap[cfg.dataUnit.keyword] = {
              name: cfg.dataUnit.name,
              unit: cfg.dataUnit.unit,
              enable: cfg.enable,
            }
            unitMap[d.id] = deviceUnitMap
          }
        }
        for (let d of devices) {
          if ([6, 7, 8].includes(d.modelId)) {
            let cable = idMap.get(d.cableId)
            if (!cable) {  // 删除了线路，但接地箱仍然存在的情况
              continue
            }
            cable.gndboxs.push(d)
            if (!d.subdevs) {
              d.subdevs = []
            }
          } else {
            let box = deviceIdMap.get(d.boxId)
            if (!box) {
              continue
            }
            if (!box.subdevs) {
              box.subdevs = []
            }
            box.subdevs.push(d)
          }
        }
        commit('setCables', cables);
        commit('setIdMap', idMap);
        commit('setDeviceIdMap', deviceIdMap);
        commit('setUnitMap', unitMap)
        commit('setActionStatus', { all: 2 })

        dispatch('getDeviceValues', refresh)
        dispatch('getDeviceAlarms', refresh)
      })
    },

    async _request({ commit, dispatch, state }, isRequest) {
      if (!isRequest && state.actionStatus.all > 0) {
        return;
      }

      commit('setActionStatus', { all: 1 })

      let res = await getCable({ ext: true }).catch((e) => {
        console.log("getCable error!", e);
        return
      })
      if (res.code !== 0 || res.data.list.length === 0) {
        console.log("res error!");
        return
      }

      commit('setActionStatus', { all: 2 })

      let list = lodash.cloneDeep(res.data.list);
      let idMap = new Map();
      let deviceIdMap = new Map();

      // 准备 unitMap
      let unitMap = {}
      list.forEach(cable => {
        idMap.set(cable.id, cable);
        if (cable.gndboxs) {
          let gndboxs = [];
          cable.gndboxs.forEach(ground => {
            //check ground type
            if (ground.model.type === 2) {
              let subdevs = [];
              deviceIdMap.set(ground.id, ground)
              if (ground.subdevs) {
                ground.subdevs.forEach(subDevice => {

                  // 写入 unitMap
                  let deviceUnitMap = {}
                  for (let cfg of subDevice.devCfgs) {
                    let cfgData = cfg.dataUnit
                    deviceUnitMap[cfgData.keyword] = {
                      name: cfgData.name,
                      unit: cfgData.unit,
                      enable: cfg.enable,
                    }
                  }
                  unitMap[subDevice.id] = deviceUnitMap

                  // 3 代表智能设备
                  if (subDevice.model.type === 3) {
                    deviceIdMap.set(subDevice.id, subDevice)
                    subdevs.push(subDevice)
                  }
                })
              }
              ground.subdevs = subdevs;
              gndboxs.push(ground);
            }
          })
          cable.gndboxs = gndboxs;
        }
      });

      commit('setCables', list);
      commit('setIdMap', idMap);
      commit('setDeviceIdMap', deviceIdMap);
      commit('setUnitMap', unitMap)

      dispatch('getDeviceValues', isRequest)
      dispatch('getDeviceAlarms', isRequest)
    }
  },
  getters: {
    getCablesCount: (state) => (id) => {
      return state.list.length;
    },
    getById: (state) => (id) => {
      let map = state.idMap;
      let ret = {
        id: id,
        name: '',
        label: '',
      }
      let item = map.size > 0 ? map.get(id) : ret;
      if (!id || !item) {
        return ret;
      }
      return item;
    },
    getByAbbr: (state) => (abbr) => {
      return state.list.find(item => { return item.abbreviation === abbr })
    },
    getByAbbreviation: (state) => (abbr) => {
      let ab = []
      for (let i = 0; i < state.list.length; i++) {
        if (state.list[i].abbreviation === abbr.abbreviation) {
          ab.push(state.list[i])
        }
      }
      return ab
    },
    getDeviceById: (state) => (id) => {
      id = parseInt(id)
      let map = state.deviceIdMap;
      let ret = {
        id: id,
        name: id,
        label: id,
      }
      let item = map.size > 0 ? map.get(id) : ret;
      if (!id || !item) {
        return ret;
      }
      return item;
    },
    getDevicesByConds: (state) => (conds) => {
      let l = []
      for (let item of state.deviceIdMap.values()) {
        let isValid = true
        for (let [k, v] of Object.entries(conds)) {
          // 可以是 array 条件
          if (Array.isArray(v)) {
            if (v.includes(item[k])) {
              continue
            } else {
              isValid = false
              break
            }
          }

          if (item[k] !== v) {
            isValid = false
            break
          }
        }
        if (isValid) {
          l.push(item)
        }
      }
      return l
    },
    isGroundDevice: (state) => (device) => {
      if (device && device.model && device.model.type) {
        return device.model.type === 2;
      }
      return false;
    },
    getDeviceValueById: (state) => (id) => {
      let map = state.deviceValuesIdMap;
      let ret = {
        online: false,
        time: "0000-00-00 00:00:00",
        devCfgs: [],
      }
      let mapItem = map.size > 0 ? map.get(id) : ret;
      if (!id || !mapItem) {
        return ret;
      }
      return mapItem;
    },
    getDeviceAlarmsById: (state) => (id) => {
      let map = state.deviceAlarmsIdMap;
      let ret = {
        list: [],
        map: new Map(),
      }
      let mapItem = map.size > 0 ? map.get(id) : ret;
      if (!id || !mapItem) {
        return ret;
      }
      return mapItem;
    },
    isDeviceAlarm: (state, getters) => (id) => {
      let ground = getters.getDeviceById(id);
      if (ground.model && ground.model.type === 2) {
        if (ground.subdevs) {
          for (let i = 0; i < ground.subdevs.length; i++) {
            let subDevice = ground.subdevs[i];
            if (subDevice?.model?.type !== 2) {
              if (getters.isDeviceAlarm(subDevice.id) === true) {
                return true;
              }
            }
          }
        }
      } else {
        let deviceAlarms = getters.getDeviceAlarmsById(id);
        if (deviceAlarms.list.length > 0) {
          return true;
        }
      }
      return false;
    },
    firstDevice: (state) => {
      if (state.list) {
        if (state.list.length > 0) {
          for (let c of state.list) {
            for (let b of c.gndboxs) {
              if (b.subdevs.length > 0) {
                return b.subdevs[0].id
              }
            }
          }
        }
      }
    },
    getUnitMap: (state) => (id) => {
      return state.unitMap[id]
    },
  }
}
