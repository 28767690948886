export default {
  立式: {
    zh: '立式',
    en: 'Vertical',
    fr: 'Vertical',
  },
  壁挂式: {
    zh: '壁挂式',
    en: 'Wall-mounted',
    fr: 'Mural',
  },
  固封式: {
    zh: '固封式',
    en: 'Sealed',
    fr: 'Scellé',
  },
}