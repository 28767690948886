export default {
  title: {
    zh: '高压电缆综合在线监测系统',
    en: 'High Voltage Powerline Management',
    fr: 'Gestion des Lignes à Haute Tension',
  },
  dropdownEditUser: {
    zh: '账号信息',
    en: 'User info',
    fr: 'Informations de l\'utilisateur',
  },
  dropdownChangePassword: {
    zh: '修改密码',
    en: 'Change password',
    fr: 'Changer le mot de passe',
  },
  dropdownChangeSettings: {
    zh: '设置',
    en: 'Settings',
    fr: 'Paramètres',
  },
  dropdownLogOut: {
    zh: '注销',
    en: 'Log out',
    fr: 'Se déconnecter',
  },
  userDialogTitle: {
    zh: '账号信息',
    en: 'Profile',
    fr: 'Profil',
  },
  settingsDialogTitle: {
    zh: '系统设置',
    en: 'Settings',
    fr: 'Paramètres',
  },
  username: {
    zh: '账号',
    en: 'Username',
    fr: 'Nom d\'utilisateur',
  },
  company: {
    zh: '所属公司',
    en: 'Company',
    fr: 'Entreprise',
  },
  nickname: {
    zh: '昵称',
    en: 'Nickname',
    fr: 'Surnom',
  },
  phone: {
    zh: '手机',
    en: 'Phone',
    fr: 'Téléphone',
  },
  email: {
    zh: '电子邮箱',
    en: 'Email',
    fr: 'E-mail',
  },
  password: {
    zh: '旧密码',
    en: 'Current password',
    fr: 'Mot de passe actuel',
  },
  newPassword: {
    zh: '新密码',
    en: 'New password',
    fr: 'Nouveau mot de passe',
  },
  checkPass: {
    zh: '重复新密码',
    en: 'Confirm new password',
    fr: 'Confirmer le nouveau mot de passe',
  },
  cancel: {
    zh: '取消',
    en: 'Cancel',
    fr: 'Annuler',
  },
  confirm: {
    zh: '确定',
    en: 'Confirm',
    fr: 'Confirmer',
  },
  useNonMapMode: {
    zh: '使用非地图展示',
    en: 'Use non-map mode',
    fr: 'Utiliser le mode non-map',
  },
  useCustomPlatformName: {
    zh: '使用自定义平台名',
    en: 'Use custom title',
    fr: 'Utiliser un titre personnalisé',
  },
  customPlatformName: {
    zh: '自定义平台名',
    en: 'Custom title',
    fr: 'Titre personnalisé',
  },
  validatePasswordNotIdentical: {
    zh: '两次输入的密码不一致',
    en: 'The passwords you entered are not identical.',
    fr: 'Les mots de passe que vous avez saisis ne sont pas identiques.',
  },
  validatePassword: {
    zh: '请输入旧密码',
    en: 'Please enter the current password.',
    fr: 'Veuillez saisir le mot de passe actuel.',
  },
  validateNewPassword: {
    zh: '请输入新密码',
    en: 'Please enter the new password.',
    fr: 'Veuillez saisir le nouveau mot de passe.',
  },
  validateCheckPass: {
    zh: '请再次输入新密码',
    en: 'Please enter the new password again.',
    fr: 'Veuillez saisir à nouveau le nouveau mot de passe.',
  },
  messagePasswordChangeSuccess: {
    zh: '密码修改成功',
    en: 'Successfully changed password.',
    fr: 'Mot de passe modifié avec succès.',
  },
  messagePasswordChangeFailure: {
    zh: '密码修改失败',
    en: 'Password change failed.',
    fr: 'Le changement de mot de passe a échoué.',
  },
  placeholderUsername: {
    zh: '请输入账号',
    en: 'Please enter username.',
    fr: 'Veuillez saisir le nom d\'utilisateur.',
  },
  placeholderPassword: {
    zh: '请输入密码',
    en: 'Please enter password.',
    fr: 'Veuillez saisir le mot de passe.',
  },
  placeholderCaptcha: {
    zh: '请输入验证码',
    en: 'Please enter the text you see in the image on the right.',
    fr: 'Veuillez entrer le texte que vous voyez dans l\'image à droite.',
  },
  buttonLogIn: {
    zh: '登录',
    en: 'Sign in',
    fr: 'Se connecter',
  },
  validateLoginUsername: {
    zh: '请输入正确的账号',
    en: 'Please enter a correct username.',
    fr: 'Veuillez entrer un nom d\'utilisateur correct.',
  },
  validateLoginPassword: {
    zh: '请输入正确的密码',
    en: 'Please enter the correct password.',
    fr: 'Veuillez saisir le bon mot de passe.',
  },
  validateLoginCaptcha: {
    zh: '请输入验证码',
    en: 'Please enter the text you see in the image on the right.',
    fr: 'Veuillez entrer le texte que vous voyez dans l\'image à droite.',
  },
  validateLoginCaptchaWrongFormat: {
    zh: '验证码格式不正确',
    en: 'Verification code is of wrong format.',
    fr: 'Le code de vérification est au mauvais format.',
  },
  messageLoginFailure: {
    zh: '请正确填写登录信息',
    en: 'Please fill in the login information correctly.',
    fr: 'Veuillez remplir correctement les informations de connexion.',
  },
}

