export default {
  周一: {
    zh: '周一',
    en: 'Monday',
    fr: 'lundi',
  },
  周二: {
    zh: '周二',
    en: 'Tuesday',
    fr: 'mardi',
  },
  周三: {
    zh: '周三',
    en: 'Wednesday',
    fr: 'mercredi',
  },
  周四: {
    zh: '周四',
    en: 'Thursday',
    fr: 'jeudi',
  },
  周五: {
    zh: '周五',
    en: 'Friday',
    fr: 'vendredi',
  },
  周六: {
    zh: '周六',
    en: 'Saturday',
    fr: 'samedi',
  },
  周日: {
    zh: '周日',
    en: 'Sunday',
    fr: 'dimanche',
  },
}