export default {
  无效: {
    zh: '无效',
    en: 'Invalid',
    fr: 'Invalide',
  },
  手动设置: {
    zh: '手动设置',
    en: 'Manual',
    fr: 'Manuel',
  },
  自动获取: {
    zh: '自动获取',
    en: 'Automatic',
    fr: 'Automatique',
  },
  从其他设备获取: {
    zh: '从其他设备获取',
    en: 'Obtained from another device',
    fr: 'Obtenu d\'un autre appareil',
  },
}