import service from '@/utils/request'

let baseurl = '/api/v1/companies/'

export const getCompanies = (params) => {
  return service({
    url: baseurl,
    method: 'get',
    params
  })
}

export const getCompany = ({id, ...params}) => { 
  if (id === undefined) {
    id = ''
  }
  return service({
    url: baseurl + id,
    method: 'get',
    params,
  })
}

export const addCompany = (data) => {
  return service({
    url: baseurl,
    method: 'post',
    data
  })
}

export const removeCompany = (id) => {
  if (id === undefined) {
    return 
  }
  return service({
    url: baseurl + id,
    method: 'delete',
  })
}

export const renewCompany = (data) => {
  return service({
    url: baseurl,
    method: 'patch',
    data,
  })
}
