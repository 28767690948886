export default {
  title: {
    zh: '【{0}】 线路配置向导',
    en: 'Configuration wizard for line "{0}"',
    fr: 'Assistant de configuration pour la ligne "{0}"',
  },
  buttonEnableDrag: {
    zh: '调整接地箱顺序',
    en: 'Adjust the order',
    fr: 'Ajuster l\'ordre',
  },
  dragConfirm: {
    zh: '确定调整',
    en: 'Confirm',
    fr: 'Confirmer',
  },
  dragCancel: {
    zh: '取消调整',
    en: 'Cancel',
    fr: 'Annuler',
  },
  buttonPreset: {
    zh: '配置线路通用属性',
    en: 'Presets',
    fr: 'Préréglages',
  },
  buttonCloseDialog: {
    zh: '结束配置',
    en: 'Close',
    fr: 'Fermer',
  },
  presetsDialogTitle: {
    zh: '通用属性',
    en: 'Presets for all devices of the line',
    fr: 'Préréglages pour tous les appareils de la ligne',
  },
  presetsDialogTitleInit: {
    zh: '初始化通用属性',
    en: 'Initialization of presets for all devices of the line',
    fr: 'Initialisation des préréglages pour tous les appareils de la ligne',
  },
  userCompany: {
    zh: '用户公司',
    en: 'Company',
    fr: 'Entreprise',
  },
  maintainerCompany: {
    zh: '运维厂商',
    en: 'Maintained by',
    fr: 'Entretenu par',
  },
  manufacturerCompany: {
    zh: '生产厂家',
    en: 'Manufactured by',
    fr: 'Fabriqué par',
  },
  thresholdMin: {
    zh: '告警阈值下限',
    en: 'Min',
    fr: 'Min',
  },
  thresholdMax: {
    zh: '告警阈值上限',
    en: 'Max',
    fr: 'Max',
  },
  buttonChangePresets: {
    zh: '保存当前配置并应用到所有设备',
    en: 'Save and apply presets to all devices',
    fr: 'Enregistrez et appliquez des préréglages à tous les appareils',
  },
  cancel: {
    zh: '取消',
    en: 'Cancel',
    fr: 'Annuler',
  },
  dialogBoxTitleNew: {
    zh: '新增接地箱',
    en: 'New grounding box',
    fr: 'Nouvelle boîte de terre',
  },
  dialogBoxTitleEdit: {
    zh: '编辑接地箱',
    en: 'Grouding box edit',
    fr: 'Modification de la boîte de terre',
  },
  dialogDeviceTitleNew: {
    zh: '新增智能设备',
    en: 'New smart device',
    fr: 'Nouvel appareil intelligent',
  },
  dialogDeviceTitleEdit: {
    zh: '编辑智能设备',
    en: 'Smart device edit',
    fr: 'Modification de l\'appareil intelligent',
  },
  formType: {
    zh: '类型',
    en: 'Type',
    fr: 'Type',
  },
  formBoxName: {
    zh: '杆塔沟/接地箱',
    en: 'Installation position/box name',
    fr: 'Position d\'installation/nom de la boîte',
  },
  formDeviceName: {
    zh: '位置描述',
    en: 'Installation position/device name',
    fr: 'Position d\'installation/nom de l\'appareil',
  },
  formLength: {
    zh: '电缆长度',
    en: 'Length',
    fr: 'Longueur',
  },
  formOperationAt: {
    zh: '投运时间',
    en: 'Operating from',
    fr: 'Opérant à partir de',
  },
  formServiceLife: {
    zh: '使用年限',
    en: 'Operation time',
    fr: 'Durées de l\'opération',
  },
  formUserId: {
    zh: '识别码',
    en: 'Identifier',
    fr: 'Identifiant',
  },
  formDeviceCode: {
    zh: '设备编号',
    en: 'Device code',
    fr: 'Code appareil',
  },
  formConjunct: {
    zh: '共用电缆接头的设备的设备编号',
    en: 'Device code of the other device connected to the same joint',
    fr: 'Code d\'appareil de l\'autre appareil sur la même articulation',
  },
  formComment: {
    zh: '备注',
    en: 'Remark',
    fr: 'Remarque',
  },
  formGpsModel: {
    zh: 'GPS模式',
    en: 'GPS mode',
    fr: 'Mode GPS',
  },
  formLongitude: {
    zh: '经度',
    en: 'Longitude',
    fr: 'Longitude',
  },
  formLatitude: {
    zh: '纬度',
    en: 'Latitude',
    fr: 'Latitude',
  },
  confirm: {
    zh: '确定',
    en: 'Confirm',
    fr: 'Confirmer',
  },
  dialogExitTitle: {
    zh: '确定要退出当前线路配置吗？',
    en: 'Are you sure to exit configuration wizard?',
    fr: 'Êtes-vous sûr de quitter l\'assistant de configuration ?',
  },
  dialogExitConfirm: {
    zh: '退出',
    en: 'Exit',
    fr: 'Quitter',
  },
  dialogDeleteTitle: {
    zh: '确定要删除【{0}】吗？',
    en: 'Are you sure to delete "{0}"?',
    fr: 'Voulez-vous vraiment supprimer "{0}" ?',
  },
  dialogDeleteConfirm: {
    zh: '删除',
    en: 'Delete',
    fr: 'Supprimer',
  },
  presetValidateCompany: {
    zh: '请选择用户公司',
    en: 'Please select user company.',
    fr: 'Veuillez sélectionner l\'entreprise de l\'utilisateur.',
  },
  presetValidateMaintainer: {
    zh: '请选择运维厂商',
    en: 'Please select maintenance company.',
    fr: 'Veuillez sélectionner l\'entreprise de maintenance.',
  },
  presetValidateManufacturer: {
    zh: '请选择生产厂家',
    en: 'Please select manufacture company.',
    fr: 'Veuillez sélectionner l\'entreprise de fabrication.',
  },
  formBoxValidateModel: {
    zh: '请选择接地箱接地类型',
    en: 'Please select grounding type.',
    fr: 'Veuillez sélectionner le type de mise à la terre.',
  },
  formBoxValidateName: {
    zh: '请输入杆塔沟/接地箱',
    en: 'Please enter installation position/box name.',
    fr: 'Veuillez entrer la position d\'installation/le nom de la boîte.',
  },
  formBoxValidateLongitude: {
    zh: '请输入纬度',
    en: 'Please enter longitude.',
    fr: 'Veuillez entrer la longitude.',
  },
  formBoxValidateLatitude: {
    zh: '请输入纬度',
    en: 'Please enter latitude.',
    fr: 'Veuillez entrer la latitude.',
  },
  formDeviceValidateModel: {
    zh: '请选择设备类型',
    en: 'Please select device type.',
    fr: 'Veuillez sélectionner le type d\'appareil.',
  },
  formDeviceValidateName: {
    zh: '请输入杆塔沟/接地箱',
    en: 'Please enter installation position/box name.',
    fr: 'Veuillez entrer la position d\'installation/le nom de la boîte.',
  },
  messageDeleteError: {
    zh: '请先删除该接地箱下属的所有设备',
    en: 'Please delete all devices under the grounding box first.',
    fr: 'Veuillez d\'abord supprimer tous les appareils sous la boîte de terre.',
  },
}
