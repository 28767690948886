export default {
  deviceCode: {
    zh: '设备编号',
    en: 'Device code',
    fr: 'Code appareil',
  },
  noData: {
    zh: '，无数据',
    en: ': No data.',
    fr: ': Pas de données.',
  },
  recentlyUpdated: {
    zh: '，刚刚更新',
    en: ': Updated just now.',
    fr: ': Mis à jour à l\'instant.',
  },
  updatedHowLongAgo: {
    zh: '，更新于{0}前',
    en: ': Updated {0} ago.',
    fr: ': Mis à jour il y a {0}.',
  },
  positionChangeQ: {
    zh: '确定位置修改吗？',
    en: 'Save position changes?',
    fr: 'Enregistrer les modifications de position ?',
  },
  positionChangeY: {
    zh: '确定',
    en: 'Confirm',
    fr: 'Confirmer',
  },
  positionChangeN: {
    zh: '取消',
    en: 'Cancel',
    fr: 'Annuler',
  },
  positionChangeSuccess: {
    zh: '位置更新成功',
    en: 'Successfully changed positions.',
    fr: 'Positions modifiées avec succès.',
  },
}