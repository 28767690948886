<template>
  <div id="app">
    <router-view />
  </div>
</template>


<style lang="scss" scoped>
/*
.app {
  width: 100vw;
  height: 100vh;
  background-color: #020308;
  overflow: hidden;
}
*/

  // 引入初始化样式
  @import '@/styles/main.scss';
  @import '@/styles/base.scss';
  @import '@/styles/mobile.scss';



</style>

<style>
.el-tabs__item.is-active {
  background: teal !important;
  color: white !important;
}

.el-tabs__header {
  background: rgba(76, 175, 180, 0.3) !important;
}
</style>
