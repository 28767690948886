export default {
  ofWhatDevice: {
    zh: '设备位置描述：',
    en: 'Device: ',
    fr: 'Appareil: ',
  },
  ofWhatCable: {
    zh: '所属线路：',
    en: 'Line: ',
    fr: 'Ligne: ',
  },
  ofWhatBox: {
    zh: '所属接地箱：',
    en: 'Grounding box: ',
    fr: 'Boîte de terre: ',
  },
}
