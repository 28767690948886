export default {
  title: {
    zh: '告警中心',
    en: 'Warnings',
    fr: 'Avertissements',
  },
  pleaseVisitManageCenter: {
    zh: '请至管理中心查看告警历史',
    en: 'Please visit Management Center for warning histories.',
    fr: 'Veuillez visiter le Centre de gestion pour les historiques d\'avertissement.',
  },
  ofWhatCableAndBox: {
    zh: '所属线路和接地箱',
    en: 'Line and box',
    fr: 'Ligne et boîte',
  },
  ofWhatDevice: {
    zh: '所属设备',
    en: 'Device',
    fr: 'Appareil',
  },
  warningItem: {
    zh: '告警项',
    en: 'Item',
    fr: 'Article',
  },
  warningValue: {
    zh: '告警值',
    en: 'Value',
    fr: 'Valeur',
  },
  timeOfLatest: {
    zh: '最近告警时间',
    en: 'Latest warning at',
    fr: 'Dernier avertissement à',
  },
  timeOfWarningOrConfirming: {
    zh: '首次告警/确认时间',
    en: 'Started/Confirmed at',
    fr: 'Commencé/Confirmé à',
  },
  frequency: {
    zh: '累计次数',
    en: 'Count',
    fr: 'Compte',
  },
  operations: {
    zh: '操作',
    en: 'Operations',
    fr: 'Opérations',
  },
  confirmInfo: {
    zh: '告警确认信息',
    en: 'Confirmation information',
    fr: 'Informations de confirmation',
  },
  solveInfo: {
    zh: '告警处理信息',
    en: 'Resolution information',
    fr: 'Informations de résolution',
  },
  cancel: {
    zh: '取消',
    en: 'Cancel',
    fr: 'Annuler',
  },
  confirm: {
    zh: '确定',
    en: 'Confirm',
    fr: 'Confirmer',
  },
  changeSuccess: {
    zh: '更改成功',
    en: 'Sucessfully changed.',
    fr: 'Modifié avec succès.',
  },
  buttonUnattended: {
    zh: '未确认',
    en: 'Unconfirmed',
    fr: 'Nonconfirmé',
  },
  buttonConfirmed: {
    zh: '已确认未处理',
    en: 'Confirmed',
    fr: 'Confirmé',
  },
  buttonToConfirm: {
    zh: '确认告警',
    en: 'Confirm',
    fr: 'Confirmer',
  },
  buttonToRepair: {
    zh: '确认已处理',
    en: 'Resolve',
    fr: 'Résoudre',
  },
}
